.blog {
  padding-top: 40px;
  padding-bottom: 40px;
}
.blog__subtitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;

  @include rm(768) {
    font-size: 24px;
  }

  @include rm(1024) {
    font-size: 28px;
  }
}

.blog__text {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 40px;
}

.steps__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 15px;

  @include rm(768) {
    font-size: 22px;
  }
  @include rm(1024) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.steps__text {
  font-size: 18px;
  line-height: 30px;
}

.steps__text:not(:last-of-type) {
  margin-bottom: 40px;
}

.steps__bio {
  padding: 20px;
  padding-bottom: 0;
  background-color: $purple;
  background-image: url("../img/bio-shadow.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  margin-bottom: 55px;

  @include rm(1024) {
    padding: 40px;
    padding-bottom: 0;
    padding-top: 30px;
  }
}

.steps__bio-wrap {
  @include rm(640) {
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: center;
  }

  @include rm(1024) {
    //gap: 70px;
    justify-content: flex-start;
  }
}

.steps__bio-list {
  margin-bottom: 12px;
}

.steps__bio-item {
  position: relative;
  padding-left: 10px;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 6px;
    background: url("../img/arrow-bio.svg");
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

.steps__bio-item:not(:last-of-type) {
  margin-bottom: 8px;
}

.steps__bio-info {
  display: none;

  @include rm(640) {
    display: block;
    color: white;
    max-width: 300px;
    width: 100%;
  }
}

.steps__bio-info h4 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

.steps__bio-img {
  max-width: 280px;
  width: 100%;
  margin: 0 auto;

  @include rm(640){
    margin: 0;
  }
}

.steps__bio-title {
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  margin-bottom: 25px;

  @include rm(768) {
    font-size: 18px;
    margin-bottom: 30px;
  }
  @include rm(1024) {
    font-size: 20px;
    margin-bottom: 35px;
  }
}

.blog__left {
  @include rm(1024) {
    width: 100%;
    margin-right: 40px;
  }
}

.blog__aside {
  display: none;

  @include rm(1024) {
    display: block;
    flex-shrink: 0;
    width: 300px;
    margin-top: 0px;
  }
}

.blog__wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  @include rm(1024) {
    display: flex;
    justify-content: space-between;
  }
}

.aside__title {
  @include rm(1024) {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}


.blog__aside .post__img-wrap img {
  @include rm(1024) {
    object-fit: cover;
    width: 100%;
    height: 120px;
    overflow: hidden;
  }
}

.blog__aside .post__img-wrap {
  @include rm(1024) {
    height: 120px;
    overflow: hidden;
  }
}

.blog__aside .post:not(:last-of-type) {
  @include rm(1024) {
    margin-bottom: 20px;
  }
}

.steps__bio--second .steps__bio-wrap {
  @include rm(640){
    flex-direction: row-reverse;
  }
}

.steps__bio--slavic-1 {
  padding-left: 0;
  padding-right: 0;

  @include rm(480){
    padding: 20px 20px 0;
  }
}

.steps__bio--slavic-2 .steps__bio-wrap,
.steps__bio--asian-1 .steps__bio-wrap {
  width: calc(100% + 40px);
  margin-left: -20px;

  @include rm(480){
    width: 100%;
    margin: 0;
  }
}
