.links {
  margin-bottom: 10px;

  @include rm(640){
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-bottom: 30px;
  }

  @include rm(768){
    order: 1;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
    display: flex;
  }

  @include rm(1024){
    width: auto;
    gap: 10px;
  }
}

.links.active {
  //color: $yellow;
  cursor: default;
}

.links__link {
  position: relative;
  transition: all 0.5s;
  font-weight: 500;
  width: 100%;
  max-width: 244px;
  font-size: 18px;
  text-align: center;
  display: block;
  color: black;
  margin: 0 auto;
  padding: 10px;

  @include rm(640){
    max-width: 200px;
  }

  @include rm(768) {
    font-size: 14px;
  }

  @include rm(1024) {
    cursor: pointer;
  }
}

.links__link.active {
  position: relative;
  cursor: default;
  font-weight: bold;
  color: #51225D;

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: -4px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background: url(../img/play-arrow.svg) no-repeat;
    background-size: 100%;
  }
}
