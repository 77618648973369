.nav {
  width: 100%;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -999px);
  //transition: all .8s ease-in-out;

  @include rm(768) {
    height: auto;
    width: 100%;
    position: static;
    transform: translate(0);
    background: transparent;
    box-shadow: none;
    backdrop-filter: none;
  }
}

.nav .logo, .logo {
  width: 100%;
  max-width: 140px;
  margin: 0;

  @include rm(768){
    max-width: 130px;
  }

  @include rm(1024){
    max-width: 160px;
  }
}

.logo-mob {
  @include rm(768){
    display: none;
  }
}
.logo-desk {
  display: none;
  @include rm(768){
    display: block;
  }
}


.nav.active {
  @include r(767){
    height: calc(100vh - 50px);
    overflow: auto;
    width: 100%;
    padding: 10px 24px 80px;
    transform: translateX(-50%);
    z-index: 10;
    background: white;
    //transition: all .8s ease-in-out;
  }

}

.nav__wrapper {
  max-width: 325px;
  width: 100%;
  margin: 0 auto;

  @include rm(640){
    max-width: 100%;
  }

  @include rm(768) {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
