.promo {
  overflow: hidden;
  background: #97C9E8;
  padding-top: 30px;

  @include rm(640){
    padding-top: 0;
  }

  &_pages {
    padding: 24px 0;

    @include rm(480){
      padding: 48px 0;
    }

    @include rm(640){
      padding-top: 48px;
    }
  }

  .container{
    @include rm(640){
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    &_pages {
      @include rm(640){
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 10px;
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
    font-weight: 700;
    color: $black;

    @include rm(360){
      font-size: 24px;
      text-align: left;
    }

    @include rm(480){
      text-align: center;
      justify-content: center;
    }

    @include rm(640){
      text-align: left;
      font-size: 28px;
    }

    @include rm(768) {
      margin-bottom: 15px;
    }

    @include rm(1220){
      font-size: 32px;
    }

    &_pages {
      text-align: left;

      @include rm(480){
        text-align: left;
      }

      @include rm(640){
        text-align: center;
        justify-content: center;
      }
    }
  }

  &__text {
    font-size: 14px;

    a {
      color: $black;
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @include rm(480){
      text-align: center;
    }
    @include rm(640){
      text-align: left;
    }

    @include rm(1024){
      font-size: 16px;
    }

    &_pages {
      text-align: left;
      margin-bottom: 0;
      display: none;

      @include rm(480){
        text-align: left;
      }

      @include rm(640){
        text-align: center;
      }
      @include rm(768) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0 4px;
      }
    }
  }

  &__wrapper {

    @include rm(640){
      width: 100%;
      align-self: center;
      padding-bottom: 30px;
      padding-top: 30px;
      margin-right: 10px;
    }

    @include rm(1024){
      margin-right: 0px;
      max-width: 370px;

      padding-top: 0px;
      padding-bottom: 0px;
    }

    @include rm(1220){
      max-width: 460px;//test
    }

    &_pages {

      @include rm(640){
        padding: 0;
        margin-right: 0;
      }

      @include rm(1024){
        max-width: unset;
      }

      @include rm(1220){
        max-width: unset;
      }
    }
  }

  &__btn {
    max-width: 200px;
    margin: 0 0 25px;

    @include rm(480){
      margin: 0 auto 25px;
    }

    @include rm(640){
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  &__break {
    display: none;

    @include rm(768){
      display: block;
    }

    &_pages {
      @include rm(768){
        display: none;
      }
    }
  }

  &__img-wrapper {
    max-width: 315px;
    width: 100%;
    position: relative;
    //padding-top: 30px;
    margin: 0 auto;



    @include rm(480){
      max-width: 350px;
    }
    @include rm(640){
      max-width: 330px;
      margin: 0;
    }
    @include rm(768){
      max-width: 385px;

    }
    @include rm(1024){
      display: flex;
      align-items: flex-end;
      justify-content: center;
      max-width: 550px;
      //min-height: 400px;
    }

    @include rm(1220){
      max-width: 690px;
    }
  }

  &__img {
    max-width: 280px;
    width: 100%;
    position: relative;
    z-index: 2;
    margin: 0 auto;

    @include rm(480){
      //max-width: 380px;
      max-width: 380px;
    }
    @include rm(640){
      max-width: 320px;
    }

    @include rm(768){
      max-width: 385px;
    }

    @include rm(1024){
      max-width: 530px;
    }
    @include rm(1220){
      max-width: 100%;
    }
  }

  &__img--small {
    max-width: 200px;
    padding-top: 30px;

    @include rm(480){
      max-width: 220px;
    }

    @include rm(640){
      margin-top: 20px;
      max-width: 230px;
    }

    @include rm(768){
      margin-top: 20px;
      max-width: 250px;
    }
    @include rm(1024){
      margin-top: 10px;
      max-width: 370px;
    }
  }

  &__circle {
    position: absolute;
    width: 320px;
    height: 320px;
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #51225d;
    border-radius: 50%;

    @include rm(480){
      width: 380px;
      height: 380px;
    }
    @include rm(640){
      width: 320px;
      height: 320px;
    }
    @include rm(768){
      width: 385px;
      height: 385px;
    }
    @include rm(1024){
      width: 550px;
      height: 550px;
      top: auto;
      bottom: -215px;
    }
    @include rm(1220){
      width: 690px;
      height: 690px;
      top: auto;
      bottom: -150px;
    }
  }
}
