.faq__wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.faq {
  padding-top: 40px;
  padding-bottom: 40px;

  &__text {
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 25px;

  }
  &__item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &.open  {
      padding-top: 30px;
      background:  #F2F6F6;
      padding-bottom: 15px;
    }

    &-title {
      padding-left: 10px;
      padding-right: 35px;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      position: relative;
      z-index: 1;
      min-height: 34px;
      cursor: pointer;
      padding-bottom: 24px;
      //border-bottom: 1px solid #7F7F7F;


      &:before {
        position: absolute;
        content: "";
        height: 1px;
        width: 100%;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background: #7F7F7F;

        .open & {
          width: calc(100% - 20px);
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 10px;
        top: 0;
        width: 14px;
        height: 14px;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.46876 9.89941C0.46876 9.2744 0.975434 8.76773 1.60045 8.76773L18.1985 8.76773C18.8235 8.76773 19.3302 9.27441 19.3302 9.89942C19.3302 10.5244 18.8235 11.0311 18.1985 11.0311L1.60045 11.0311C0.975435 11.0311 0.468761 10.5244 0.46876 9.89941Z' fill='%23333333'/%3E%3Cpath d='M9.8995 19.3301C9.27449 19.3301 8.76781 18.8235 8.76781 18.1985L8.76781 1.60037C8.76781 0.975353 9.27448 0.46868 9.89949 0.46868C10.5245 0.46868 11.0312 0.975354 11.0312 1.60037L11.0312 18.1985C11.0312 18.8235 10.5245 19.3301 9.8995 19.3301Z' fill='%23333333'/%3E%3C/svg%3E");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        .open & {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='4' viewBox='0 0 20 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.46876 1.89941C0.46876 1.2744 0.975434 0.767727 1.60045 0.767727L18.1985 0.767732C18.8235 0.767732 19.3302 1.27441 19.3302 1.89942C19.3302 2.52443 18.8235 3.03111 18.1985 3.03111L1.60045 3.0311C0.975435 3.0311 0.468761 2.52443 0.46876 1.89941Z' fill='%23333333'/%3E%3C/svg%3E%0A");
        }
      }
    }
    &-text {
      margin-bottom: 0;
      color: #666666;
      max-height: 0;
      overflow: hidden;
      font-size: 14px;
      transition: all .8s;
      font-weight: 500;
      padding-left: 10px;
      padding-right: 10px;

      .open & {
        transition: all .8s;
        max-height: 300px;
        margin-top: 25px;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    &__text {
      font-size: 18px;
      margin-bottom: 80px;
    }
  }

  @media screen and (min-width: 768px) {
    &__text {
      margin-bottom: 60px;
    }
    &-title {
      font-size: 18px;
    }
  }

  @media screen and (min-width: 480px) {
    &__item {
      //padding: 25px;
      padding-bottom: 0;

      &-title {
        min-height: 1px;
      }
      &-text {
        font-size: 16px;
      }

      &.open  {
        padding-bottom: 25px;
      }
    }
  }


}
