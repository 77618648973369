.popup-quiz-latina {
  display: none;
}

.popup-quiz-latina .popup-quiz__wrapper {
  flex-direction: column;
}

.popup-quiz-latina .popup-quiz__title {
  font-size: 15px;
  color: #171219;

  @include rm(480){
    font-size: 16px;
  }

  @include rm(640){
    margin-bottom: 15px;
  }

  @include rm(1024){
    font-size: 22px;

  }
}

.popup-quiz-latina.popup-quiz--bg {
  background: white;
  padding: 0px 10px 10px;
  border: 1px solid #51225d;

  @include rm(768){
    padding-top: 0;
    padding-bottom: 0;
  }
}

.popup-quiz-latina .popup-quiz__right {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0px;

  @include rm(640){
    padding-bottom: 0;
  }

  @include rm(768){
    max-width: 345px;
    margin: auto;

  }

  @include rm(1024){
    max-width: 390px;
  }
}

.popup-quiz-latina .popup-quiz__right h3 {
  text-align: center;
  color: #171219;
  margin-bottom: 10px;

  @include rm(640){
    margin-bottom: 20px;
    line-height: normal;
  }

  @include rm(1024){
    margin-bottom: 35px;
  }
}

.popup-quiz-latina .popup-quiz__column {
  flex-direction: column;

  @include rm(375){
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }

  @include rm(640) {
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }
}

.popup-quiz-latina .popup-quiz__input {
  display: block;
  font-size: 14px;
  border: 1px solid #d1d0d1;
  color: #171219;
  margin: 0 auto;
  margin-bottom: 10px;

  padding: 16px 24px;

  &::placeholder {
    color: #171219;
  }

  @include rm(640){
    margin-bottom: 15px;
  }

  @include rm(1024){
    max-width: 320px;
    margin-bottom: 20px;
  }
}

.popup-quiz-latina .popup-quiz__form {
  flex-direction: column;

  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  @include rm(480){
    margin-bottom: 20px;
  }
  @include rm(640){
    margin-bottom: 25px;
  }

  @include rm(1024){
    margin-bottom: 35px;
    justify-content: flex-start;
  }
}

.popup-quiz-latina .popup-quiz__button {
  display: block;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 25px 24px;
  background: #FF9518;

  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "SUBMIT";
  }

  @include rm(1024){
    max-width: 320px;
  }
}


.popup-quiz-latina .popup-quiz__close {
  margin-bottom: 5px;
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 3;
}

.popup-quiz-latina .popup-quiz__footnote {
  color: #171219;

  @include rm(1024){
    text-align: center;
  }
}

.popup-quiz-latina .popup-quiz__footnote a {
  color: #171219;
}

.popup-quiz-latina .popup-quiz__left {


  @include rm(768){
  //  width: 100%;
  //  max-width: 310px;
  //
  //  margin-left: -25px;


    max-width: 454px;
    margin-left: -155px;
  }

  @include rm(1024){

    margin-left: -65px;
  }
}
.popup-quiz-latina .popup-quiz-img {
  max-width: 100%;
  width: 100%;
  margin: 0 auto 10px;

  @include rm(375){
    max-width: 360px;
  }
  @include rm(480){
    max-width: 330px;
  }
  @include rm(640){
    margin: 0;
  }
  @include rm(768){
    max-width: 100%;
  }
}


.hide-after.interactive::after {
  display: none;
}

.popup-quiz__title--mob {
  @include rm(375){
    display: none;
  }
  @include rm(768){
    display: block;
  }
}

.popup-quiz__title--desk {
  display: none;
  @include rm(375){
    display: block;
    padding-right: 33px;
    padding-left: 33px;
    padding-top: 10px;
  }

  @include rm(480){
    padding-right: 45px;
    padding-left: 45px;
  }

  @include rm(640){
    padding-top: 35px;
  }
  @include rm(768){
    display: none;
  }
}