.quiz-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px auto 0;

  @include rm(768){
    order: 2;
    flex-direction: column;
  }

  @include rm(1024){
    flex-direction: row;
    margin: 0;
  }
}

.quiz-link__text {
  margin-bottom: 15px;

  @include rm(768){
    font-size: 14px;
    margin-bottom: 5px;
  }

  @include rm(1024){
    white-space: nowrap;
    margin-bottom: 0;
    margin-right: 5px;
  }
}
.quiz-link__btn {
  text-align: center;
  max-width: 315px;
  width: 100%;
  font-weight: bold;
  background: $purple;
  padding: 16px 18px;
  color: white;
  text-transform: uppercase;
  font-size: 16px;

  &:hover, &:active {
    text-decoration: underline;
  }

  @include rm(360){
    padding: 16px 30px;
  }

  @include rm(768){
    padding: 0;
    color: $black;
    background: transparent;
  }

  @include rm(1024){
    cursor: pointer;
    position: relative;
    padding-left: 15px;

    &:before {
      position: absolute;
      content: "";
      top: 50%;
      left: 0;
      width: 10px;
      height: 10px;
      background: url("../img/play-arrow.svg") no-repeat;
      background-size: 100%;
      transform: translateY(-50%);
    }
  }

}