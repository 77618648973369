//футер к низу
html, body {
  height: 100%;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 1 auto;
}

body {
  position: relative;
  font-family: $main-font;
  font-size: 16px;
  line-height: 1.3;
  color: #171219;
  background: white;

}


img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  overflow: hidden;
}

button {
  font-family: $main-font;
  display: block;
  padding: 0;
  background: transparent;
}

.container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  @include rm(768) {
    width: 730px;
  }

  @include rm(1024) {
    width: 1024px;
  }

  @include rm(1220) {
    width: 1220px;
  }
  @include rm(1440) {
    width: 1280px;
  }
}

.btn {
  text-align: center;
  font-weight: 700;
  width: 100%;
  display: block;
  padding: 18px;
  background-color: $purple;
  color: white;
  font-size: 16px;
  margin: 0 auto;
  cursor: pointer;
  transition: .8s;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
    background-color: #450954;
  }

  &:active {
    background-color: white;
    color: $purple;
    text-decoration: none;
  }
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.title {
  text-transform: uppercase;
  font-size: 18px;
  color: $purple;
  line-height: 37px;
  margin-bottom: 18px;


  @include rm(1024) {
    font-size: 20px;
    margin-bottom: 16px;
    line-height: 34px;
  }
}
.subtitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;

  @include rm(768){
    font-size: 24px;
  }

  @include rm(1024){
    font-size: 28px;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
