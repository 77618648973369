.blog-post {
  padding-bottom: 80px;
  padding-top: 40px;
}

.blog-post__wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.blog__text{
  font-size: 16px;
  margin-bottom: 40px;

  @include rm(768){
    font-size: 18px;
  }
}
.blog__list {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  padding-bottom: 30px;

  @include rm(640){
    max-width: 650px;
  }

  @include rm(1024){
    max-width: 100%;
    display: flex;
    gap: 20px;
    padding-bottom: 0px;
  }
}





/* the slides */
.blog__list .slick-slide {
  margin: 0 15px;
  margin-bottom: 20px;
}

/* the parent */
.blog__list .slick-list {
  margin: 0 -15px;
}
.blog__list .slick-next, .slick-prev {
  display: none!important;
}

.blog__list .slick-dots li button {
  width: 10px;
  height: 10px;
}


.blog__list .slick-dots li {
  border: 1px solid $purple;
  width: 18px;
  height: 18px;
  padding: 3px;
  border-radius: 50%;
}
.blog__list .slick-dots li button:before {
  width: 10px;
  height: 10px;
}
.blog__list .slick-dots li.slick-active button {
  padding: 0;
  width: 10px;
  height: 10px;
  background: $purple;
  border-radius: 50%;
}

.blog__list .slick-dots {
  bottom: 0;
}