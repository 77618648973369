.product {
  padding-top: 40px;
  padding-bottom: 40px;
}

.product__subtitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;

  @include rm(768) {
    font-size: 24px;
  }

  @include rm(1024) {
    font-size: 28px;
  }
}

.product__wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  @include rm(1024) {
    display: flex;
    justify-content: space-between;
  }
}

.product__left .product__right-popular {
  @include rm(1024){
    display: none;
  }
}

.product__left {
  margin-bottom: 80px;
  @include rm(1024) {
    width: 100%;
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.product__right {
  @include rm(1024) {
    flex-shrink: 0;
    width: 300px;
    margin-top: 0px;
  }
}

.product__right .product__right-popular {
  display: none;

  @include rm(1024){
    display: block;
  }
}

.product__step {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 15px;

  @include rm(768) {
    font-size: 22px;
  }
  @include rm(1024) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.product__text {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 40px;

  a {
    color: #97C9E8;
    font-weight: bold;
    text-decoration: underline;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
    }
  }
}

.product__list {
  list-style: decimal;
  padding-left: 25px;
}
.product__item {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 40px;
}


//.product__text:not(:last-of-type) {
//  margin-bottom: 40px;
//}
//

.product__banner {
  padding: 20px;
  padding-bottom: 0;
  background-color: $purple;
  background-image: url("../img/bio-shadow.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  margin-bottom: 55px;
  margin-top: 40px;

  @include rm(1024) {
    padding: 40px;
    padding-bottom: 0;
    padding-top: 40px;
  }
}

.product__banner-img {
  margin: 0 auto;
}

.product__banner-title {
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  margin-bottom: 25px;

  @include rm(768) {
    font-size: 18px;
    margin-bottom: 30px;
  }
  @include rm(1024) {
    font-size: 20px;
    margin-bottom: 35px;
  }
}

.product__btn {
  max-width: 320px;
  margin: 0 auto;

  @include rm(1024) {
    max-width: 200px;
    width: 100%;
    margin-left: 0;
  }
}

.product__stats {
  margin-bottom: 55px;
  @include rm(480) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}

.product__stats-wrapper {
  max-width: 100%;
  width: 100%;
  background: #F2F6F6;
  padding: 20px 20px 40px;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  @include rm(480){
    &:not(:last-of-type) {
      margin-bottom: 0px;
    }
  }

  @include rm(640){
    max-width: 100%;
    width: calc(50% - 10px);
  }

  //@include rm(768){
  //  max-width: 320px;
  //}
  //
  //@include rm(1024){
  //  max-width: 310px;
  //}
}

.product__stats-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

.product__stats-item {
  font-size: 16px;
  line-height: 22px;
  position: relative;
  padding-left: 15px;

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 10px;
    height: 10px;
    background: url("../img/play-arrow.svg") no-repeat;
    background-size: 100%;
    transform: translateY(-50%);

  }

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}
.product__right-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 35px;

  @include rm(1024){
    font-size: 32px;
    margin-bottom: 25px;
  }
}

.product-banner {
  position: relative;
  background: #F2F6F6;
  box-shadow: 0px 4px 16px rgba(23, 18, 25, 0.1);
  padding: 25px 20px;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;

  &:before {
    content: '1';
    padding: 10px;
    top: 0;
    left: 0;
    width: 38px;
    height: 47px;
    text-align: center;
    position: absolute;
    font-size: 16px;
    line-height: 1;
    color: white;
    z-index: 2;
    background: url("../img/flag.svg");
  }

  @include rm(640){
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 100%;
  }


  @include rm(1024){
    display: block;
    max-width: 320px;
    margin: 0;
  }
}

.product-banner__title {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto 25px;

  @include rm(640){
    margin-bottom: 15px;
  }

  @include rm(1024){
    margin: 0 auto 25px;
    max-width: 200px;
  }
}

.product-banner__rating {


  text-align: center;
  font-size: 24px;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  @include rm(640){
    margin-bottom: 15px;
  }
  @include rm(1024){
    margin-bottom: 35px;
  }
}

.product-banner__question {
  width: 20px;
  height: 20px;
  @include rm(1024){
    cursor: pointer;
  }
}

.product-banner__info {
  margin-bottom: 35px;

  @include rm(640){
    margin-bottom: 0;
  }
  @include rm(1024){
    margin-bottom: 35px;
  }
}

.product-banner__bottom {
  @include rm(1024){
    width: 100%;
    max-width: 215px;
    margin: 0 auto;
  }
}

.product-banner__visit {
  text-transform: uppercase;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;

  @include rm(1024){
    margin: 0;
    max-width: 210px;
  }
}

.product-banner__advanteges {
  position: relative;
  font-size: 14px;
  padding-left: 15px;
  color: $black;

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 10px;
    height: 10px;
    background: url("../img/play-arrow.svg") no-repeat;
    background-size: 100%;
    transform: translateY(-50%);
  }

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}
.product-banner--popular {
  position: relative;
  border: 2px solid #FF9518;
  padding-top: 50px;

  &:before {
    background: url("../img/flag-popular.svg");
  }

  &:after {
    position: absolute;
    content: "🔥 Popular Choice";
    color: white;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    padding: 14px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    background: url("../img/popular-bg.svg") no-repeat;
    background-position: center;
    line-height: 1.2;
    font-size: 16px;
    width: 188px;
    height: 63px;
  }

  @include rm(1024){
    &:after {
      transform: none;
      left: 55px;
    }
  }
}

.product-banner--popular-dating {
  &:after {
    position: absolute;
    content: "🔥 Niche Dating";
  }
}

.product-banner--popular-senior {
  &:after {
    position: absolute;
    content: "🔥 Senior Dating";
    //background: url("../img/senior-bg.svg") no-repeat;
    //background-position: center;
  }
}


.product-banner--simple {
  border: 2px solid #97C9E8;
  &:before {
    background: url(../img/flag.svg);
  }
  &:after {
    display: none;
  }
}



.asianfeels .product-banner:before,
.funchatt .product-banner:before,
.amourfeel .product-banner:before{
  content: '4';
}



.asianmelodies .product-banner:before,
.amourfactory .product-banner:before,
.Latinfeels .product-banner:before{
  content: '3';
}

.easternhoneys .product-banner:before,
.lovefort .product-banner:before,
.jollyromance .product-banner:before{
  content: '2';
}

.loverwhirl .product-banner:before,
.rondevo .product-banner:before {
  content: '5';
}

.product__right-popular {
  margin-bottom: 40px;
}

.product-platform__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  column-gap: 20px;
  justify-content: space-around;
  background: #F2F6F6;
  padding: 35px 20px;

  @include rm(640){
    flex-direction: row;
  }

  @include rm(1024){
    display: block;
    padding: 35px 10px;
  }
}

.product-platform {
  width: 100%;
  max-width: 270px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:not(:last-of-type){
    border-bottom: 1px solid rgba(127, 127, 127, 0.5);
    padding-bottom: 25px;
    margin-bottom: 25px;
  }

  @include rm(375){
    flex-direction: row;
  }

  @include rm(640){
    max-width: 275px;
    &:nth-child(3){
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  @include rm(1024){
    &:not(:last-of-type){
      border-bottom: 1px solid rgba(127, 127, 127, 0.5);
      padding-bottom: 25px;
      margin-bottom: 25px;
    }
  }

}

.product-platform__title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 25px;

  @include rm(1024){
    //font-size: 32px;
    margin-bottom: 20px;
  }
}


.product-platform-wrapper {

  max-width: 125px;
  width: 100%;
  margin-bottom: 10px;

  @include rm(375){
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.product-platform__visit {
  padding: 14px;
  text-transform: uppercase;
  max-width: 140px;
  width: 100%;
  margin: 0;
}
.product-platform__rating {
  display: flex;
  font-size: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
}

.product-platform__question {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.product-platform__img {
  margin-bottom: 10px;
}

.product-banner--desk {

  @include rm(640){
    display: none;
  }

  @include rm(1024){
    display: block;
  }
}

.product-banner--mob {
  display: none;
  @include rm(640){
    display: block;
  }
  @include rm(1024){
    display: none;
  }
}


.product__banner--inner img {
  margin-right: -20px;

  @include rm(1024){
    margin-right: -40px;
  }
}