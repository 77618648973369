.checked {

  @include rm(375) {
    padding-top: 16px;
  }

  @include rm(640) {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

.checked-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6px;

  @include rm(640){
    gap: 10px;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
  @include rm(1024){
    justify-content: flex-start;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }
}

.checked-item {
  font-weight: bold;
  font-size: 14px;
  position: relative;
  padding-left: 24px;

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: url("../img/check-circle.svg") no-repeat;
    background-size: 100%;
  }

  @include rm(640){
    padding-left: 30px;
    font-size: 16px;
    &:before {
      width: 24px;
      height: 24px;
    }
  }
}
