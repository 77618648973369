/* Гамбургер иконка */
.burger {
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  background: $purple;
  z-index: 10;

  @include rm(768) {
    display: none;
  }
}

.burger span {
  width: 14px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  background-color: white;
}

.burger span:nth-of-type(2) {
  top: 10px;
  right: 0;
}

.burger span:nth-of-type(3) {
  top: auto;
  right: 0;
  bottom: 8px;
}

/* Меняем гамбургер иконку, когда меню открыто */
.burger.active span:nth-of-type(1) {
  display: none;
}

.burger.active span:nth-of-type(2) {
  width: 14px;
  top: 50%;
  left: 9px;
  transform: translate(0%) rotate(45deg);
}

.burger.active span:nth-of-type(3) {
  width: 14px;
  top: 50%;
  bottom: 0;
  transform: translate(-50%, 0%) rotate(-45deg);
}

.hidden {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}


