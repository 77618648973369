.quiz-block {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}

.quiz__wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.quiz__text {
  font-size: 18px;
  margin-bottom: 40px;

  @include rm(1024) {
    margin-bottom: 45px;
  }
}


.interactive__bg {


  overflow: hidden;
  padding: 50px 20px 0px 20px;
  background-color: $purple;
  background-image: url(../img/quiz-shadow.png);
  background-position: top right;
  background-repeat: no-repeat;
  height: 500px;

  @include rm(375) {
    padding: 40px 20px 0px 20px;
  }
  @include rm(480) {
    padding: 50px 20px 0px 20px;
  }


  @include rm(1024) {
    padding: 55px 0 0 20px;
    padding-top: 80px;
    padding-left: 40px;
  }
}

.interactive {
  position: relative;

  &:after {
    position: absolute;
    content: "🔎 Find the Platform";
    color: #fff;
    top: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 14px 0 0px;
    text-align: center;
    background: url(../img/popular-bg.svg) no-repeat;
    background-position: center;
    line-height: 1.2;
    font-size: 16px;
    width: 188px;
    height: 60px;
    z-index: 3;
  }
}

.interactive.active .interactive__bg {
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 40px 10px 12px 10px;

  &:before {
    position: absolute;
    content: '';
    background-color: #97C9E8;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    bottom: -160px;
    width: 570px;
    height: 520px;
  }

  @include rm(480) {
    padding: 40px 28px 10px 28px;

    &:before {
      bottom: -260px;
      width: 622px;
      height: 628px;
    }
  }
  @include rm(640) {
    padding: 45px 40px 40px 40px;

    &:before {
      bottom: -290px;
      bottom: -370px;
      width: 760px;
      height: 730px;
    }
  }

  @include rm(1024) {
    background-size: 100%;
    padding: 60px 60px 65px 60px;

    &:before {
      top: 160px;
      right: -146px;
      width: 1500px;
      height: 1500px;
    }
  }


}

.interactive__right {

  width: calc(100% + 20px);
  //margin-left: -10px;

  padding-top: 30px;
  max-width: 260px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 15px;
    width: 49px;
    height: 52px;
    background: url("../img/clap.png") no-repeat center;
    background-size: 100%;
    z-index: 2;
  }

  &:before {
    position: absolute;
    content: '';
    background-color: #97C9E8;
    border-radius: 50%;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    height: 525px;
  }

  @include rm(375) {
    width: 100%;
    max-width: 315px;
    margin-left: auto;
    margin-right: auto;
  }

  @include rm(768) {
    max-width: 325px;

    &:before {
      width: 522px;
      height: 528px;
    }
  }

  @include rm(1024) {
    padding-top: 0px;
    margin-right: 0;
    max-width: 505px;

    &:after {
      top: -40px;
      width: 67px;
      height: 70px;
    }
    &:before {
      transform: none;
      left: auto;
      //top: -5px;
      //right: -157px;

      top: -29px;
      right: -166px;
      width: 722px;
      height: 728px;
    }
  }
}


.interactive__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;

  @include rm(640) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @include rm(1024) {
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
  }
}

.interactive__title {
  width: 100%;
  max-width: 290px;
  font-size: 17px;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
  text-transform: uppercase;

  @include rm(375) {
    text-align: center;
    margin: 0 auto 10px;
  }

  @include rm(480) {
    max-width: 470px;
    margin-bottom: 15px;
  }
  @include rm(640) {
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
  }
  @include rm(768) {
    font-size: 24px;
  }
  @include rm(1024) {
    text-align: left;
    margin-left: 0;
    margin-bottom: 30px;
    font-size: 28px;
    max-width: 630px;
  }
}

.interactive__subtitle {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
  color: white;


  @include rm(375) {
    max-width: 280px;
    margin: 0 auto 15px;
  }
  @include rm(480) {
    max-width: 470px;
    margin: 0 auto 18px;
  }
  @include rm(768) {
    font-size: 16px;
  }
  @include rm(1024) {
    font-size: 18px;
    margin-bottom: 30px;
  }
}

.interactive__subtitle--mob {
  @include rm(1024){
    display: none;
  }

}

.interactive__subtitle--desk {
  display: none;

  @include rm(1024){
    display: block;
  }
}

.interactive__btn {
  font-weight: bold;
  max-width: 210px;
  width: 100%;
  background: #97C9E8;
  text-transform: uppercase;
  color: white;
  padding: 18px;
  margin-bottom: 5px;

  &:hover {
    text-decoration: underline;
    background-color: #8EBED9;
  }

  &:active {
    color: #97C9E8;
    border: 1px solid #97C9E8;
    background-color: white;
    text-decoration: none;
  }

  @include rm(375) {
    margin: 0 auto 5px;
  }

  @include rm(480) {
    margin: 0 auto 15px;
  }

  @include rm(640) {
    margin-bottom: 0;
    margin-left: auto;
  }
  @include rm(1024) {
    cursor: pointer;
    margin-left: 0;
  }
}

.interactive__img {
  border-radius: 30px;
  border: 10px solid #F2F6F6;
  position: relative;
  z-index: 2;
  //height: 215px;
  //margin: 0 auto;


  @include rm(375) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    width: 100%;
    //margin-left: auto;
    //max-width: 300px;
    //height: 247px;
  }
  @include rm(480) {
    //max-width: 318px;
    height: auto;
  }
  @include rm(640) {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 330px;
    border-bottom: 0;
  }

  @include rm(768){
    max-width: 100%;
  }

  @include rm(1024) {
    border-top-right-radius: 0;
    border-right: 0;
    //max-width: 500px;
  }
}

.interactive__left {
  align-self: flex-start;

  width: 100%;
  max-width: 250px;

  @include rm(375) {
    text-align: center;
    max-width: 100%;
  }
  @include rm(640) {

    //margin-top: 30px;
    //max-width: 230px;
  }

  @include rm(768) {
    text-align: left;
  }

  @include rm(1024) {
    max-width: 380px;
  }
}

.interactive-block {
  margin-top: 80px;
}


//quiz box
.quiz-box {
  position: relative;
  z-index: 2;
  height: 100%;
}
.quiz-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}



.quiz-box h2 {
  text-transform: uppercase;
  text-align: center;
  padding: 0;
  margin: 0 0 8px;
  color: white;
  font-weight: bold;
  font-size: 17px;
  //min-height: 45px;

  @include rm(375) {
    font-size: 18px;
  }
  @include rm(640) {
    font-size: 22px;
  }
  @include rm(768) {
    font-size: 24px;
  }
  @include rm(1024) {
    font-size: 28px;
  }
}

.quiz-box__list {


  width: 100%;
  display: flex;
  flex-wrap: wrap;
  //flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;

  @include rm(640) {
    margin-bottom: auto;
    gap: 10px;
  }
  @include rm(1024) {
    gap: 20px;
  }
}

.quiz-box__item {

  width: 100%;
  max-width: 300px;
  height: 118px;

  background: #F2F6F6;
  overflow: hidden;
  font-size: 20px;
  margin: 0;
  box-shadow: 0px 4px 16px rgba(23, 18, 25, 0.08);
  border-radius: 25px;

  @include rm(640){
    max-width: 210px;
    height: 170px;
  }

  @include rm(768) {
    max-width: 260px;
  }

  @include rm(1024) {
    max-width: 235px;
    height: 230px;
  }

  @include rm(1220) {
    max-width: 250px;
    height: 240px;
  }
}

.quiz-box__item label img {
  width: 100%;
  display: block;
  object-fit: contain;
  height: 60px;
  margin: 0 auto;



  @include rm(640) {
    height: 100px;
  }
  @include rm(1024) {
    height: 130px;
  }

  @include rm(1220) {
    height: 140px;
  }
}

.quiz-box__item label span {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: bold;

  @include rm(1024) {
    font-size: 18px;
  }
}

.quiz-box__item label {
  overflow: hidden;
  border: 10px solid #F2F6F6;
  background: white;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 0 0;
  cursor: pointer;
  border-radius: 25px;

  @include rm(640){
    padding: 10px 0 0;
    border: 10px solid #F2F6F6;
  }

  @include rm(1024){
    padding: 20px 0 0;
  }
}

.quiz-box .answer {
  display: none;
}

.quiz-box .question-text {
  color: white;
  text-align: center;
  margin-bottom: 5px;
}

.counter-next {
  color: #ffffff3d;
}

.quiz-box .animation-result {
  display: none;
  height: 200px;
  background: yellow;
}


.quiz-box .loading {
  display: none;
  position: relative;
  width: 100%;
  text-align: center;
  padding-bottom: 70px;
  padding-top: 100px;


  @include rm(640) {
    padding-top: 60px;
  }
  @include rm(1024) {
    padding-top: 100px;
    padding-bottom: 0px;
  }
}

.quiz-box .loading-text {
  color: white;
  font-size: 18px;
  margin-top: 10px;
}

.quiz-box {
  display: none;
}

.finish {
  display: none;

}

.finish-title {
  max-width: 320px;
  width: 100%;
  margin: 0 auto 16px;

  font-size: 14px;
  text-align: center;
  color: white;


  @include rm(640) {
    font-size: 16px;
  }
  @include rm(640) {
    margin-bottom: 20px;
  }
  @include rm(768) {
    max-width: 100%;
    font-size: 18px;
  }
  @include rm(1024) {
    font-size: 20px;
    margin-bottom: 22px;
  }
}

.finish-box__title {
  font-size: 20px;
  color: $black;
  font-weight: 700;
  margin-bottom: 10px;


  @include rm(1024) {
    font-size: 24px;
    margin-bottom: 24px;
  }
}

.finish-title span {
  font-weight: bold;
}

.finish-box {
  position: relative;
  max-width: 315px;
  width: 100%;
  margin: 0 auto;
  color: $black;
  background: white;
  border: 6px solid #f2f6f6;
  border-radius: 15px;
  padding: 6px 6px 10px;
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;


  @include rm(375) {
    border: 10px solid #f2f6f6;
    border-bottom: none;
    padding: 10px ;
  }
  @include rm(480) {
    max-width: 330px;
    border: 10px solid #f2f6f6;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: none;
  }

  @include rm(640) {
    max-width: 550px;
    border-bottom: 0;
    padding-bottom: 25px;
    min-height: 345px;
  }

  @include rm(1024) {
    border: 13px solid #f2f6f6;
    border-top: 7px solid #f2f6f6;
    border-bottom: 0;
    min-height: 360px;
    max-width: 600px;
    width: 100%;
    padding-top: 17px;
    margin: 0 auto;
  }
}

.finish-box__text {
  margin-bottom: 15px;
}


.finish-box__text,
.finish-box__subtitle,
.finish-box__text-second {
  font-size: 14px;

  @include rm(375){
    font-size: 16px;
  }
}

.finish-box .finish__btn {
  display: block;
  text-align: center;
  max-width: 100%;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  margin-top: -25px;

  @include rm(480) {

    margin-left: 0;
  }

  @include rm(640) {
    max-width: 210px;
    margin-top: 35px;
  }
}

.finish-box__subtitle {
  margin-bottom: 8px;
}

.finish-img {
  margin-bottom: 10px;

  @include rm(640) {
    margin-bottom: 15px;
  }
  @include rm(1024) {
    margin-bottom: 20px;
  }
}

.finish-box__img {
  transition: all 2s;
  opacity: 0;
  transition: 2s;
  width: 130px;
  max-width: 130px;
  margin-left: -30px;


  @include rm(640) {
    opacity: 1;
    width: 100%;
    max-width: 265px;
    margin: 0;

    position: absolute;
    bottom: -140px;
    right: 0;
  }

  @include rm(768) {
    max-width: 295px;
  }


  @include rm(1024) {
    max-width: 320px;
  }
}

.finish-box__circle {
  position: relative;
  z-index: 1;
}

.finish-box__column {
  @include rm(640) {
    display: flex;
    gap: 20px;
    align-items: flex-start;
  }
}

.finish-box__column-right {
  margin-bottom: 10px;

  @include rm(1024) {

    max-width: 350px;
    width: 100%;
    margin-bottom: 0;
  }
}

.finish-box__column-left {
  position: relative;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include rm(640) {
    position: static;
    width: 100%;
  }
}


.finish-box__fade-1,
.finish-box__fade-2,
.finish-box__fade-3{
  //display: none;
  opacity: 0;
  transition: all 2s;
}

.finish-box__fade-4 {
  display: none;
}

.finish__btn-mob {
  @include rm(640) {
    display: none;
  }
}


.circular-chart {
  z-index: 2;
  margin: 0px auto;
  width: 120px;

  @include rm(640) {
    width: 145px;
  }

  @include rm(1024) {
    width: 165px;
  }
}

.circle-bg {
  fill: none;
  stroke: #F2F6F6;
  stroke-width: 6;
}

.circle {
  stroke-dasharray: 90, 100;
  fill: none;
  stroke-width: 9;
  stroke: $purple;
  animation: progress 3s ease-out;
}

.percentage {
  fill: #363636;
  font-size: 0.5em;
  text-anchor: middle;
  font-family: Helvetica;
  font-weight: bold;
}

@keyframes progress {
  0% {
    //stroke: transparent;
    stroke-dasharray: 0 100;
  }
}
