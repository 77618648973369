.pages {
  margin-bottom: 20px;

  @include rm(640){
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 20px;
    justify-content: center;
    margin-bottom: 30px;
  }
  @include rm(768) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    justify-content: flex-start;
    gap: 10px;
    order: 3;
  }
  @include rm(1024){
    margin: 0;
    gap: 30px;
    order: 0;
  }
}

.pages__link {
  max-width: 325px;
  width: 100%;
  font-size: 18px;
  text-align: center;
  display: block;
  padding: 10px;
  color: $black;
  margin: 0 auto;

  @include rm(768){
    color: $gray;
    font-size: 14px;
  }

  @include rm(1024) {
    cursor: pointer;
  }
}

.pages__wrapper {
  @include rm(768){
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    row-gap: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;

    &:before {
      position: absolute;
      content: "";
      width: 100vw;
      height: 1px;
      background: rgba(127,127,127,.5);
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.logo__link {
  @include rm(1024){
    width: 100%;
  }
  @include rm(1220){
    width: auto;
  }
}